<template>
  <div>
    <div v-loading="loading">
      <div class="content-goods-publish">
        <el-form :model="baseInfoForm" status-icon :rules="baseInfoFormRule" label-position="right" ref="baseInfoForm"
          label-width="120px" class="demo-ruleForm">
          <div class="base-info-item">
            <div>
              <el-form-item label="礼包名称" prop="gift_name" class="goods-name-width">
                <el-input v-model="baseInfoForm.gift_name" @change="() => {
                  baseInfoForm.gift_name = baseInfoForm.gift_name.trim();
                }
                  " :maxlength="32" :minlength="1" placeholder="1-32个字符"></el-input>
              </el-form-item>
              <el-form-item label="礼包分类" prop="category_path">
                <el-cascader ref="cascader" clearable style="width: 430px;" v-model="baseInfoForm.category_path"
                  :options="GifCategoryOptions" :props="GifProps" placeholder="请选择礼包分类" class="cas-select" />
              </el-form-item>
              <el-form-item label="礼包图片" prop="goods_gallery" style="width: 90%;text-align: left;">
                <el-upload class="avatar-uploader goods-images"
                  :class="baseInfoForm.goods_gallery_list.length >= 5 && 'no-plus-icon'"
                  :action="`${MixinUploadApi}?scene=goods`" list-type="picture-card" multiple :limit="5"
                  :on-exceed="handleExceed" :file-list="goodsGalleryList" :on-preview="handlePictureCardPreview"
                  :before-upload="beforeAvatarUpload" :on-remove="handleRemove" :on-success="handleSuccess">
                  <!-- <span style="display: none;"  v-model="baseInfoForm.goods_gallery"></span> -->
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div slot="tip" class="el-upload__tip">
                    建议图片尺寸比例为4:3，大小不超5MB，只限png/jpg格式，最多上传5张图片。
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogImage">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>

              <el-form-item label="选择商品" prop="goods_data" class="goods-name-width">
                <el-button type="primary" size="small" @click="showDialog = true">选择商品</el-button>
              </el-form-item>

              <!-- 商品选择区 start -->
              <el-form-item style="width:100%;" class="goods-select">
                <en-table-layout :tableData="goodsData" style="width: 100%" ref="tableWrap">
                  <template slot="table-columns">
                    <el-table-column label="图片" width="70" class-name="goods-cover-wrapper" fixed="left"
                      :key="'thumbnail'">
                      <template slot-scope="{row}">
                        <el-popover placement="right" trigger="hover">
                          <img :src="row.thumbnail" alt="" style="width: 300px">
                          <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference" />
                        </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column label="商品名称" :show-overflow-tooltip="true" fixed="left" width="200"
                      :key="'goods_name'">
                      <template slot-scope="scope">
                        {{ scope.row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
                      </template>
                    </el-table-column>
                    <el-table-column label="前端名称" :show-overflow-tooltip="true" width="200" :key="'goods_alias'">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.goods_alias"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="商品来源" width="100" :key="'goods_source'">
                      <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
                      <template slot-scope="scope">
                        <template v-if="scope.row.self_operated">
                          <template v-if="scope.row.self_operated === 0">
                            <span v-if="scope.row.goods_source === 0">
                              精选优品
                            </span>
                            <span v-else>
                              京东商品
                            </span>
                          </template>
                          <template v-else>
                            自有商品
                          </template>
                        </template>
                        <template v-else>
                          <span v-if="scope.row.goods_source === 0">精选优品</span>
                            <span v-if="[5,9].includes(scope.row.goods_source)">自有商品</span>
                          <span v-if="scope.row.goods_source === 1">京东商品</span>
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column width="100" label="所属供应商" show-overflow-tooltip
                      v-if="MixinIsFormEnterprise && $store.getters.isSupplierName">
                      <template slot-scope="scope">
                        {{ scope.row.supplier_name || scope.row.seller_name }}
                      </template>
                    </el-table-column>
                    <el-table-column label="销售价" :key="'revise_price'" :show-overflow-tooltip="true" width="100">
                      <template slot-scope="scope">
                        <span>
                          {{ scope.row.revise_price | unitPrice('￥') }}
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column label="市场价" :key="'mktprice'" :show-overflow-tooltip="true" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.mktprice | unitPrice('￥') }}
                      </template>
                    </el-table-column>

                    <el-table-column label="分销价" :key="'enterprise_revise_price'" :show-overflow-tooltip="true"
                      width="120">
                      <template slot-scope="scope">
                        {{ scope.row.enterprise_revise_price | unitPrice('￥') }}
                      </template>
                    </el-table-column>

                    <el-table-column label="会员价" :key="'price'" :show-overflow-tooltip="true" width="120">
                      <template slot-scope="scope">
                        {{ scope.row.price | unitPrice('￥') }}
                      </template>
                    </el-table-column>
                    <el-table-column label="数量" width="90" :key="'num'">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.num" @input="getPrice"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="库存" width="100" :key="'quantity'">
                      <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
                      <template slot-scope="scope">
                        <template v-if="scope.row.goods_source === 1">
                          <template v-if="scope.row.enable_quantity > 0">有货</template>
                          <template v-else>无货</template>
                        </template>
                        <template v-else>
                          {{ scope.row.enable_quantity || 0 }}件
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column label="状态" width="140" :key="'status'">
                      <template slot-scope="scope">
                        <span v-if="scope.row.shop_goods_status === 0 || scope.row.disabled === 0">已下架<span
                            style="color:red;">（已失效）</span></span>
                        <span v-else>已上架</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="操作" :show-overflow-tooltip="true" fixed="right" :key="'opt'">
                      <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                      </template>
                    </el-table-column>
                  </template>
                </en-table-layout>
              </el-form-item>
              <!-- 商品选择器 end -->
              <div v-if="MixinIsFormEnterprise">
                <el-form-item label-width="100px" label="套餐价" prop="combo_price" :maxlength="10" :inline-message="true"
                  style="float:left;">
                  <el-input @input="getPrice(1, 'change_combo_price')" placeholder="请输入"
                    v-model="baseInfoForm.combo_price" class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="100px" label="分销总价" prop="enterprise_sum_price" :maxlength="10"
                  :inline-message="true" style="float:left;">
                  <el-input placeholder="请输入分销总价" v-model="baseInfoForm.enterprise_sum_price" disabled
                    class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="100px" label="销售总价" prop="shop_sum_price" :maxlength="10"
                  :inline-message="true" style="float:left;">
                  <el-input placeholder="请输入销售总价" v-model="baseInfoForm.shop_sum_price" disabled class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <div style="clear:both;"></div>
                <el-form-item label-width="100px" label="利润率" prop="profit_price" :maxlength="10" :inline-message="true"
                  style="float:left;">
                  <el-input placeholder="请输入利润率" v-model="baseInfoForm.profit_price" disabled class="price-input">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="100px" label="市场总价" prop="market_sum_price" :maxlength="10"
                  :inline-message="true" style="float:left;">
                  <el-input placeholder="请输入市场总价" v-model="baseInfoForm.market_sum_price" disabled class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="100px" label="会员总价" prop="sum_price" :maxlength="10" :inline-message="true"
                  style="float:left;">
                  <el-input placeholder="请输入会员总价" v-model="baseInfoForm.sum_price" disabled class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <div style="clear:both;"></div>
              </div>
              <div v-else>
                <el-form-item label-width="100px" label="套餐价" prop="combo_price" :maxlength="10" :inline-message="true"
                  style="float:left;">
                  <el-input placeholder="请输入" v-model="baseInfoForm.combo_price" class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="100px" label="分销总价" prop="enterprise_sum_price" :maxlength="10"
                  :inline-message="true" style="float:left;">
                  <el-input placeholder="请输入分销总价" v-model="baseInfoForm.enterprise_sum_price" disabled
                    class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <div style="clear:both;"></div>
                <el-form-item label-width="100px" label="销售总价" prop="shop_sum_price" :maxlength="10"
                  :inline-message="true" style="float:left;">
                  <el-input placeholder="请输入销售总价" v-model="baseInfoForm.shop_sum_price" disabled class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="100px" label="市场总价" prop="market_sum_price" :maxlength="10"
                  :inline-message="true" style="float:left;">
                  <el-input placeholder="请输入市场总价" v-model="baseInfoForm.market_sum_price" disabled class="price-input">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <div style="clear:both;"></div>
              </div>
              <el-form-item label="礼包详情" class="goods-intro" style="width:85%;">
                <UE ref="ue" :defaultMsg="baseInfoForm.intro"></UE>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <!--底部-->
      <div class="footer">
        <el-button-group>
          <!-- <el-button
            type="primary"
            size="small"
            @click="pre"
            >返回</el-button
          > -->
          <el-button :loading="createGoodsButtonLoading" type="primary" size="small" @click="aboveGoods">{{ $route.params
            && $route.params.id ? '确认修改' : '确认创建' }}</el-button>
        </el-button-group>
      </div>
    </div>
    <en-goods-picker-fx :show="showDialog" :showChooseBtn="false" :default-data="goodsData" @close="showDialog = false"
      @chooseAll="allChoose" />
  </div>
</template>

<script>
import * as API_Combo from "@/api/combo-card-shop/combo";
import * as API_Login from '@/api/login';
import { RegExp } from "@/../ui-utils";
import Sortable from "sortablejs";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import { UE } from "@/components";
import { $giftsCategory } from '@/pages/goods/services/gifts-category'
import * as $GoodsCategoryApi from "@/api/goodsCategory";
export default {
  name: "comboEdit",
  components: {
    [UE.name]: UE,
    EnTableLayout,
  },
  data () {
    const checkCombo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("套餐价格不能为空"));
      } else if (value == 0) {
        callback(new Error("套餐价格必须大于零"));
      }
      if (!RegExp.money.test(value)) {
        callback(new Error("请输入数字值"));
      } else {
        // if (parseFloat(value) > parseFloat(this.baseInfoForm.shop_sum_price)) {
        //   callback(new Error("市场价格必须大于等于套餐"));
        // } else {
        callback();
        // }
      }
    };

    const checkGoodsGallery = (rule, value, callback) => {
      setTimeout(() => {
        if (!this.baseInfoForm.goods_gallery) {
          callback(new Error("请选择商品相册"));
        } else {
          callback();
        }
      }, 1000);
    };

    const checkGoodsData = (rule, value, callback) => {
      setTimeout(() => {
        if (!this.goodsData.length) {
          callback(new Error("请选择商品"));
        } else {
          callback();
        }
      }, 1000);
    };

    return {
      GifCategoryOptions: [],
      GifProps: {
        emitPath: false,
        checkStrictly: true,
        expandTrigger: "hover",
        label: 'name',
        children: 'children',
        value: 'category_path'
      },
      /** 加载中。。。 */
      loading: false,

      /** 当前商品id*/
      comboId: "",

      /** 商品详情信息提交表单 */
      baseInfoForm: {
        category_path: '',
        /** 商品名称 */
        gift_name: "",

        /** 商品相册列表 */
        goods_gallery_list: [],

        /** 用来校验的商品相册 */
        goods_gallery: "",

        /** 套餐价格  */
        combo_price: "",

        /** 销售总价格 */
        shop_sum_price: 0,

        /** 市场总价格 */
        market_sum_price: 0,

        /** 分销总价格 */
        enterprise_sum_price: 0,

        /** 会员总价 */
        sum_price: 0,

        /** 商品描述 详情 */
        intro: "",
        /** 利润率 */
        profit_price: 0,
        change_combo_price: 0,
      },

      /** 临时存储商品相册信息 */
      goodsGalleryList: [],

      /** 商品图片放大地址 */
      dialogImageUrl: "",

      /** 是否显示放大图片弹框 */
      dialogImage: false,

      /** 商品详情的校验规则 */
      baseInfoFormRule: {
        gift_name: [
          { required: true, message: "请输入礼包名称", trigger: "blur" },
          {
            whitespace: true,
            message: "礼包名称不可为纯空格",
            trigger: "blur",
          },
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        category_path: [
          { required: true, message: '请选择礼包分类', trigger: 'change' },
        ],
        goods_data: [{ required: true, validator: checkGoodsData, trigger: "blur" }],
        combo_price: [
          { required: true, message: "请输入套餐价格", trigger: "blur" },
          { validator: checkCombo, trigger: "blur" },
        ],
        shop_sum_price: [
          { required: true, message: "请输入销售总价格", trigger: "blur" },
        ],
        market_sum_price: [
          { required: true, message: "请输入市场总价格", trigger: "blur" },
        ],
        enterprise_sum_price: [
          { required: true, message: "请输入分销总价格", trigger: "blur" },
        ],
        sum_price: [
          { required: true, message: "请输入会员总价格", trigger: "blur" },
        ],
        goods_gallery: [
          { required: true, validator: checkGoodsGallery, trigger: "change" },
        ],
        profit_price: [
          { required: true, message: "请输入利润率", trigger: "blur" }
        ]
      },
      createGoodsButtonLoading: false,
      goodsData: [],
      /** 选择器相关 */
      showDialog: false,
      chooseChangeList: [],
      firstPriceShowFlag: true,
    };
  },
  filters: {
    /** 销售状态格式化 */
    marketStatus (row) {
      switch (row.shop_goods_status) {
        case 0:
          return "已下架";
        case 1:
          return "已上架";
      }
    },
  },
  watch: {
    goodsData: function () {
      // 如果是编辑，价格的第一次回显使用接口返回的价格，而不是客户端自己计算的
      if (
        this.$route.params &&
        this.$route.params.id &&
        this.firstPriceShowFlag
      ) {
        this.firstPriceShowFlag = !this.firstPriceShowFlag;
      } else {
        this.getPrice();
      }
    },
    'baseInfoForm.category_path': {
      handler (val) {
        console.log('baseInfoForm.category_path', val, typeof val, this.$refs.cascader.panel.menus[0]);

      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (this.$route.params && this.$route.params.id) {
      this.comboId = this.$route.params.id;
      this.GET_GoodData();
    }
    next();
  },
  async mounted () {
    if (sessionStorage.getItem('MixinIsFormEnterprise')) {
      this.MixinIsFormEnterprise = sessionStorage.getItem('MixinIsFormEnterprise')
    }
    this.GifCategoryOptions = await $GoodsCategoryApi.getAllGiftsCategoryLevelList(0)
    API_Login.getLoginType().then(res => {
      if (res && res.parentLogin === 1) {
        this.MixinIsFormEnterprise = true;
        sessionStorage.setItem('MixinIsFormEnterprise', true)
      } else sessionStorage.setItem('MixinIsFormEnterprise', false)
    })
    if (this.$route.params && this.$route.params.id) {
      this.comboId = this.$route.params.id;
      this.GET_GoodData();
    }
    console.log(this.MixinIsFormEnterprise)
  },
  methods: {
    getPrice (type = 0, key = '') {
      this.baseInfoForm.change_combo_price = 1
      if (key === '') {
        this.baseInfoForm.change_combo_price = 0
      } else {
        this.baseInfoForm[key] = type
      }
      this.baseInfoForm = {
        ...this.baseInfoForm,
        ...this.goodsData.reduce((p, c) => {
          p.combo_price =
            this.baseInfoForm.change_combo_price === 1
              ? this.baseInfoForm.combo_price
              : this.addPrice(
                p.combo_price,
                this.xPrice(c.revise_price, c.num)
              )
          p.shop_sum_price = this.addPrice(p.shop_sum_price, this.xPrice(c.revise_price, c.num));
          p.market_sum_price = this.addPrice(p.market_sum_price, this.xPrice(c.mktprice, c.num));
          p.enterprise_sum_price = this.addPrice(p.enterprise_sum_price, this.xPrice(c.enterprise_revise_price, c.num));
          p.sum_price = this.addPrice(p.sum_price, this.xPrice(c.price, c.num));
          // 利润率计算
          p.profit_price = ((p.shop_sum_price - p.sum_price) / p.shop_sum_price * 100).toFixed(2)
          return p;
        },
          {
            combo_price: 0,
            /** 销售总价格 */
            shop_sum_price: 0,

            /** 市场总价格 */
            market_sum_price: 0,

            /** 分销总价格 */
            enterprise_sum_price: 0,

            /** 会员总价 */
            sum_price: 0,
            /** 利润率 */
            profit_price: 0
          }
        ),
      };
    },
    xPrice (a, num) {
      let ret = 0;
      for (let index = 0; index < num; index++) {
        ret = this.addPrice(ret, a);
      }
      return ret;
    },
    /**
     * 两位小数加法
     */
    addPrice (a, b) {
      a = a.toString();
      b = b.toString();
      a.split(".")[1]
        ? a.split(".")[1].length === 1 && (a += "0")
        : (a += "00");
      b.split(".")[1]
        ? b.split(".")[1].length === 1 && (b += "0")
        : (b += "00");
      return (Number(a.replace(".", "")) + Number(b.replace(".", ""))) / 100;
    },
    handleDelete (index, row) {
      this.goodsData.splice(index, 1);
    },
    /** 保存商品选择器选择的商品 */
    allChoose (list) {
      // list.forEach((item) => {
      //   for (let index = 0; index < this.goodsData.length; index++) {
      //     const e = this.goodsData[index];
      //     if (item.goods_id === e.goods_id) {
      //       this.$set(item, "goods_alias", e.goods_alias);
      //       this.$set(item, "num", e.num);
      //       break;
      //     }
      //   }
      //   !item.goods_alias && this.$set(item, "goods_alias", item.goods_name);
      //   !item.num && this.$set(item, "num", 1);
      // });
      console.log(list, 'list');
      const originGoodsId = this.goodsData.map(item => { return item.goods_id })
      list.forEach(i => {
        if (originGoodsId.indexOf(i.goods_id) === -1) {
          this.$set(i, 'num', 1)
          this.$set(i, 'goods_alias', i.goods_name)
          this.goodsData.push(i)
        }
      })
    },
    /** 返回*/
    pre () {
      this.loading = true;
    },

    /** 上架  */
    aboveGoods () {
      // 检验礼包内商品的别名不能为空
      if (!this.goodsData.every(i => i.goods_alias)) {
        this.$message.error('请补充完整商品的前端名称');
        return;
      }
      this.createGoodsButtonLoading = true;
      let _params = this.generateFormData(this.baseInfoForm);
      // return;
      this.$refs["baseInfoForm"].validate((valid) => {
        if (valid) {
          if (Array.isArray(_params.category_path)) _params.category_path = _params.category_path.at(-1)
          const categoryUrl = _params.category_path.split("|");
          _params.category_id = categoryUrl[categoryUrl.length - 2]
          if (this.comboId) {
            /** 修改礼包 */
            API_Combo.editCombo(this.comboId, _params)
              .then(() => {
                this.$message.success("修改礼包成功");
                this.$store.dispatch("delCurrentViews", {
                  view: this.$route,
                  $router: this.$router,
                });
              })
              .finally(() => {
                this.createGoodsButtonLoading = false;
              });
          } else {
            /** 创建礼包 */
            API_Combo.addCombo(_params)
              .then(() => {
                this.$message.success("创建礼包成功");
                this.$store.dispatch("delCurrentViews", {
                  view: this.$route,
                  $router: this.$router,
                });
              })
              .finally(() => {
                this.createGoodsButtonLoading = false;
              });
          }
        } else {
          this.createGoodsButtonLoading = false;
          this.$message.error(
            "表单中存在未填写或者填写有误的地方,已有错误标示，请检查并正确填写"
          );
        }
      });
    },

    /** 搜索单个商品信息*/
    GET_GoodData () {
      API_Combo.getCombo(this.comboId).then((response) => {
        this.baseInfoForm = {
          ...response,
        };
        this.baseInfoForm["profit_price"] = ((this.baseInfoForm["shop_sum_price"] - this.baseInfoForm["sum_price"]) /
          this.baseInfoForm["shop_sum_price"] * 100).toFixed(2)
        /** 商品相册校验属性 */
        this.baseInfoForm.goods_gallery_list.forEach((key) => {
          this.$set(key, "url", key.original);
        });
        this.$nextTick(() => {
          this.setSort();
        });
        this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
        this.goodsGalleryList = JSON.parse(
          JSON.stringify(this.baseInfoForm.goods_gallery_list)
        );
        this.baseInfoForm.category_path = this.handlePath(this.baseInfoForm.category_path)
        this.goodsData = this.genGoodsData(response.goods_volist);
        this.$forceUpdate()
      });
    },
    handlePath (path) {
      if (typeof path !== 'string') return path;
      const res = []
      let start = 0
      let end = 2
      const nPath = path.split('|')
      let len = nPath.length
      if (!len) return [];
      while (end < len) res.push(`${nPath.slice(start, end++).join('|')}|`)
      return res
    },
    /** 文件列表  图片个数 校验*/
    handleExceed (files, fileList) {
      this.$message.warning(`最多上传5张图`);
    },
    /** 文件列表移除文件时的钩子  图片删除校验*/
    handleRemove (file, fileList) {
      this.baseInfoForm.goods_gallery_list.forEach((key, index) => {
        if (key.img_id !== -1) {
          if (key.img_id === file.img_id) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        } else {
          if (key.name === file.response.name) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        }
      });
      if (fileList.length <= 0) {
        this.baseInfoForm.goods_gallery_list = [];
        this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
      }
      this.$refs.baseInfoForm.validateField("goods_gallery");
    },

    /** 文件列表上传成功时的钩子  上传成功校验 */
    handleSuccess (response, file, fileList) {
      this.baseInfoForm.goods_gallery_list.push({
        img_id: -1,

        original: response.url,

        url: response.url,

        sort: 0,

        name: response.name,
      });
      this.baseInfoForm.goods_gallery = this.baseInfoForm.goods_gallery_list.toString();
      this.$refs["baseInfoForm"].validateField("goods_gallery");
      this.$nextTick(() => {
        this.setSort();
      });
    },

    /** 点击已上传的文件链接时的钩子 放大 */
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogImage = true;
    },

    /** 拖拽改变图片顺序 */
    setSort () {
      const el = document.querySelectorAll(
        "div.avatar-uploader > ul.el-upload-list--picture-card"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) { },
        onEnd: (evt) => {
          let temp = this.baseInfoForm.goods_gallery_list[evt.oldIndex];
          this.baseInfoForm.goods_gallery_list[
            evt.oldIndex
          ] = this.baseInfoForm.goods_gallery_list[evt.newIndex];
          this.baseInfoForm.goods_gallery_list[evt.newIndex] = temp;
        },
      });
    },

    /** 图片上传之前的校验  */
    beforeAvatarUpload (file) {
      const isType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isType) {
        this.$message.error("上传礼包图片只能是 JPG/JPEG/PNG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传礼包图片大小不能超过 1MB!");
      }

      /** 重复校验*/
      const isExit = this.baseInfoForm.goods_gallery_list.some((key) => {
        return file.name === key.name;
      });
      if (isExit) {
        this.$message.error("图片已存在");
      }

      return isType && isLt1M && !isExit;
    },

    /** 构造表单数据 */
    generateFormData (data) {
      let _params = {
        ...data,
        goods_volist: [
          ...this.goodsData.reduce((p, c) => {
            let { goods_alias, goods_id, goods_name, num } = c;
            p = [...p, { goods_alias, goods_id, goods_name, num }];
            return p;
          }, []),
        ],
      };
      delete _params.gallery_list;
      delete _params.goods_gallery;
      if (_params.goods_gallery_list) {
        _params.goods_gallery_list.forEach((key) => {
          delete key.name;
          delete key.big;
          delete key.goods_id;
          delete key.isdefault;
          delete key.small;
          delete key.status;
          delete key.thumbnail;
          delete key.tiny;
          delete key.uid;
          delete key.url;
        });
      }

      /** 处理UE的信息 */
      _params.intro = this.$refs["ue"].getUEContent();
      return _params;
    },
    /**
     * 编辑回显构造商品数据
     */
    genGoodsData (goods_volist) {
      let ret = [];
      goods_volist.forEach((item) => {
        let profit_price = 0;
        let {
          goods_alias,
          goods_id,
          goods_name,
          supplier_name,
          num,
          shop_goods_vo: {
            enterprise_revise_price,
            goods_do: { thumbnail, goods_source, mktprice, price, enable_quantity, self_operated },
            revise_price,
            shop_goods_status,
            disabled// 值等于0时表示大平台下架商品
          },
        } = item;
        ret.push({
          goods_id,
          thumbnail,
          goods_name,
          supplier_name,
          goods_alias,
          goods_source,
          revise_price,
          mktprice,
          enterprise_revise_price,
          price,
          num,
          enable_quantity,
          shop_goods_status,
          disabled,
          profit_price,
          self_operated
        });
      });
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-goods-publish {
  padding: 15px;
  margin: 0 auto;
  text-align: center;
  background: none repeat 0 0 #fff;
  border-radius: 5px;
}

/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

div.base-info-item {
  .el-form-item {
    width: 30%;
    min-width: 300px;
  }

  .goods-name-width {
    width: 50%;
    min-width: 300px;
  }
}

.goods-select ::v-deep .el-form-item__content {
  margin-left: 50px !important;
}

/*描述*/
.goods-intro {
  * {
    line-height: 1;
  }
}

/** 底部步骤 */
.footer {
  // width: calc(100% - 280px);
  padding: 10px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  // left: 280px;
  text-align: center;
  z-index: 999;
  border-top: 1px solid #eee;
  left: 13.2%;
  right: 2%;
}

/*图片上传组件第一张图设置封面*/
.goods-images {
  /deep/ li.el-upload-list__item:first-child {
    position: relative;
  }

  /deep/ li.el-upload-list__item:first-child:after {
    content: "封";
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    left: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    padding-top: 6px;
    background: #13ce66;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  }
}

.no-plus-icon ::v-deep {
  .el-upload--picture-card {
    display: none;
  }
}

::v-deep .el-input-group__append {
  padding: 0 10px;
  color: #333;
  background: #eaf0ff;
  border: 0;
}

::v-deep .price-input .el-input__inner {
  border: 0;
}

.price-input {
  width: 130px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  overflow: hidden;
}
</style>
